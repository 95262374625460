import { useState } from 'react';
import { createPasskey } from '../../../lib/passkey';
import Banner from '../../Banner';
import BlueButton from '../../BlueButton';
import GreyButton from '../../GreyButton';

interface Props {
   onSuccess: () => void;
   onCancel: () => void;
}
export default function OfferPasskey({ onSuccess, onCancel }: Props) {
   const [discoverable, setDiscoverable] = useState(true);
   const [success, setSuccess] = useState(false);
   const [error, setError] = useState<Error>();
   const create = () => {
      setError(undefined);
      setSuccess(false); // Shouldn't really be necessary
      createPasskey(discoverable).then(() => {
         setSuccess(true);
         setTimeout(onSuccess, 2000);
      }, setError);
   };
   return (
      <>
         {success ? <Banner type="success">Passkey created</Banner> : null}
         {error ? <Banner type="error">Error creating passkey</Banner> : null}
         <h2 className="mb-6 text-left text-2xl font-extrabold text-gray-900">
            Would you like to create a passkey?
         </h2>
         <p className="my-3">
            Create a passkey to enjoy seamless secure login, managed by your
            device. Passkeys are <strong>easier</strong> and{' '}
            <strong>more secure</strong> than other authentication methods.{' '}
            <a
               href="https://support.google.com/chrome/answer/13168025"
               target="_blank"
               className="link"
            >
               Find out more
            </a>
         </p>
         <p className="my-3">
            You will always be able to use your password with an alternative
            method if your passkey isn't available.
         </p>
         <p className="my-3 font-bold">
            Do not create a passkey if this isn't your device.
         </p>
         <label className="flex flex-row gap-2 my-3">
            <input
               type="checkbox"
               checked={discoverable}
               onChange={e => setDiscoverable(e.target.checked)}
               className="mt-2"
            />
            <p className="text-sm text-gray-600">
               Passkeys are secure enough to take the place of both password and
               multi-factor authentication. If you would nevertheless like to
               use your passkey only after entering your password, uncheck this
               box.
            </p>
         </label>
         <p className="mt-5 flex align-middle items-center">
            <BlueButton className="py-2 px-8" onClick={create}>
               <span className="font-bold text-lg">Create a passkey</span>
            </BlueButton>
            <a className="link text-lg font-bold ml-5" onClick={onCancel}>
               Skip for now
            </a>
         </p>
      </>
   );
}
