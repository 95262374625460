import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ReportingConsole from './AppInsightsConsole';
import { LocationState } from 'history';

export const appInsightsInit = (browserHistory: LocationState) => {
   const connectionString =
      process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING;
   let appInsights: ApplicationInsights | null = null;
   if (connectionString) {
      const reactPlugin = new ReactPlugin();
      appInsights = new ApplicationInsights({
         config: {
            connectionString,
            disableFetchTracking: false,
            extensions: [reactPlugin],
            extensionConfig: {
               [reactPlugin.identifier]: { history: browserHistory },
            },
         },
      });
      appInsights.loadAppInsights();
      window.console = new ReportingConsole(appInsights);
   }
   return appInsights;
};
