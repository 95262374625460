import { useRouteMatch } from 'react-router-dom';
import { FundManagerBatchDocumentGet } from 'types/interface';
import Breadcrumbs from '../../components/Breadcrumbs';
import { createBreadcrumbs } from '../../components/Batches/batch';
import { BatchFolderList } from '../../components/Batches/BatchFolderList';
import BatchHeader from '../../components/Batches/BatchHeader';
import BatchStatusMessage from '../../components/Batches/BatchStatusMessage';
import {
   BaseUrl,
   handleDownloadBatch,
   handleDownloadDocument,
} from '../../pages/Batch/download';
import { useState } from 'react';
import DownloadingButton from '../DownloadingButton';
import GreyButton from '../GreyButton';

export interface BatchFolderProps {
   batch: FundManagerBatchDocumentGet;
   basePath: string;
   baseUrl: BaseUrl;
   onDeleteBatch?: () => void;
   onEditBatch?: () => void;
   isEditMode?: boolean;
   onApproveBatch?: () => void;
   onRejectBatch?: () => void;
   onAddFiles?: () => void;
   onDeleteFile?: (id: number) => void;
   onEditBatchInfo?: () => void;
   onSendNotificationBatch?: () => void;
   fundManagerUrl?: string;
   fundManagerEmails?: string[];
}

export default function BatchFolder({
   batch,
   onApproveBatch,
   onRejectBatch,
   basePath,
   baseUrl,
   onDeleteBatch,
   onEditBatch,
   onEditBatchInfo,
   isEditMode,
   onAddFiles,
   onDeleteFile,
   onSendNotificationBatch,
   fundManagerUrl,
   fundManagerEmails,
}: BatchFolderProps) {
   const match = useRouteMatch<{
      currentPath?: string;
   }>();
   const { currentPath } = match.params;
   const [downloadingBatch, setDownloadingBatch] = useState(false);

   const items = createBreadcrumbs(batch.name, currentPath ?? '', basePath);

   const diplayApproveRejectButtons =
      batch.requiresApproval && !batch.approvedAt && !batch.rejectedAt;

   const handleBatchDownload = async () => {
      setDownloadingBatch(true);
      await handleDownloadBatch(baseUrl, batch.id);
      setDownloadingBatch(false);
   };

   return (
      <div className="min-h-screen bg-gray-100">
         <div className="mx-4 py-20 md:py-24">
            <div className="max-w-5xl mx-auto">
               <BatchHeader batchName={batch.name} fundName={batch.fundName} />
               {onEditBatchInfo && (
                  <GreyButton onClick={onEditBatchInfo} className=" text-sm">
                     Edit Batch Info
                  </GreyButton>
               )}
               {fundManagerUrl && (
                  <div className="text-left mb-4">
                     Link for fund manager:{' '}
                     <a className="link" href={fundManagerUrl}>
                        {fundManagerUrl}
                     </a>
                  </div>
               )}
               {fundManagerEmails && (
                  <div className="text-left mb-4">
                     Fund managers: {fundManagerEmails.join(', ')}
                  </div>
               )}

               <BatchStatusMessage
                  requiresApproval={batch.requiresApproval}
                  approvedAt={batch.approvedAt}
                  approvedBy={batch.approvedBy?.email}
                  rejectedAt={batch.rejectedAt}
               />
               <Breadcrumbs items={items} />
               <div className="max-w-screen-lg">
                  <div className="text-right mb-2 flex justify-end">
                     <DownloadingButton
                        className="action-btn"
                        spinnerSize="sm"
                        onClick={handleBatchDownload}
                        isDownloading={downloadingBatch}
                     >
                        {' '}
                        Download Batch
                     </DownloadingButton>

                     {isEditMode && (
                        <button
                           className="action-btn ml-2"
                           onClick={onAddFiles}
                        >
                           Add Files
                        </button>
                     )}

                     {diplayApproveRejectButtons && (
                        <>
                           {onApproveBatch && (
                              <GreyButton
                                 onClick={onApproveBatch}
                                 textColor="text-green-700"
                                 className="ml-2"
                              >
                                 Approve Batch
                              </GreyButton>
                           )}

                           {onRejectBatch && (
                              <button
                                 className="delete-btn-sm ml-2"
                                 onClick={onRejectBatch}
                              >
                                 Reject Batch
                              </button>
                           )}
                        </>
                     )}
                     {onEditBatch && (
                        <button
                           className="action-btn ml-2"
                           onClick={onEditBatch}
                        >
                           {isEditMode ? 'Finish Edit' : 'Edit Batch'}
                        </button>
                     )}
                     {onSendNotificationBatch && (
                        <button
                           className="action-btn ml-2"
                           onClick={onSendNotificationBatch}
                        >
                           Resend Notification
                        </button>
                     )}
                     {onDeleteBatch && (
                        <button
                           className="delete-btn-sm ml-2"
                           onClick={onDeleteBatch}
                        >
                           Archive Batch
                        </button>
                     )}
                  </div>
                  <BatchFolderList
                     currentPath={currentPath}
                     batch={batch}
                     onDownloadDocument={documentId =>
                        handleDownloadDocument(baseUrl, documentId, batch.id)
                     }
                     onDeleteDocument={onDeleteFile}
                     basePath={basePath}
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
