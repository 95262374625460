import { useEffect, useRef, useState } from 'react';
import { MfaValidateMethodProps } from '../MfaValidate';
import { mfaApiLogin } from '../../../lib/api';
import BlueButton from '../../BlueButton';

export default function MfaValidateApp({
   selectedMethodInfo,
   onSuccess,
}: MfaValidateMethodProps) {
   const [error, setError] = useState('');
   const [loading, setLoading] = useState(false);
   const [codeToVerify, setCodeToVerify] = useState('');

   const inputRef = useRef<HTMLInputElement>(null);
   useEffect(() => inputRef?.current?.focus(), []);

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCodeToVerify(e.target.value);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!codeToVerify) {
         return setError('Please enter code');
      }

      setLoading(true);
      mfaApiLogin('post', 'mfa/app', {
         mfaAuthId: selectedMethodInfo.id,
         code: codeToVerify,
      })
         .then(() => onSuccess())
         .catch(err =>
            setError(
               err.message === 'Forbidden' || !err.message
                  ? `The code is incorrect`
                  : err.message,
            ),
         )
         .finally(() => setLoading(false));
   };

   return (
      selectedMethodInfo && (
         <>
            <h2 className="mb-1 text-left text-3xl font-extrabold text-gray-900">
               App authentication
            </h2>
            <h3 className="mb-4 text-left text-medium font-bold text-gray-900">
               {selectedMethodInfo.identifier}
            </h3>
            {error ? (
               <div className="bg-red-200 p-4 rounded-md text-left my-2">
                  {error}
               </div>
            ) : null}

            <p className="mb-4 font-regular text-gray-600">
               Enter the 6-digit code you get from the app.
            </p>

            <form className="space-y-6 group" onSubmit={handleSubmit}>
               <div>
                  <label
                     htmlFor="verification-code"
                     className="block text-sm font-medium text-gray-700"
                  >
                     Verification Code
                  </label>
                  <div className="mt-1">
                     <input
                        ref={inputRef}
                        value={codeToVerify}
                        onChange={handleChange}
                        id="phone"
                        name="phone"
                        type="text"
                        autoComplete="one-time-code"
                        inputMode="numeric"
                        minLength={6}
                        maxLength={6}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                     />
                  </div>
               </div>
               <div>
                  <BlueButton
                     className="w-full block group-invalid:pointer-events-none group-invalid:opacity-30"
                     loading={loading}
                     type="submit"
                  >
                     Verify
                  </BlueButton>
               </div>
            </form>
         </>
      )
   );
}
