import Button, { ButtonProps } from './Button';

export default function BlueButton({
   onClick,
   type = 'button',
   children,
   className,
   disabled = false,
   loading = false,
}: ButtonProps) {
   return (
      <Button
         type={type}
         className={`text-white bg-blue-600 border-transparent hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${className}`}
         onClick={onClick}
         disabled={disabled}
         loading={loading}
      >
         {children}
      </Button>
   );
}
