import { post } from '../lib/api';

interface Props {
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   name: string;
   id: string;
   className?: string;
   value?: string;
}

export default function NewPasswordInput({
   onChange,
   name,
   id,
   className,
   value,
}: Props) {
   const validatePasswordRequirments = (
      e: React.FocusEvent<HTMLInputElement>,
   ) => {
      const password = e.target.value;
      post('validate-password', { password })
         .then(() => e.target.setCustomValidity(''))
         .catch(err => e.target.setCustomValidity(err));
   };

   return (
      <input
         minLength={8}
         required
         onChange={e => {
            e.target.setCustomValidity('');
            onChange(e);
         }}
         onBlur={validatePasswordRequirments}
         value={value}
         type="password"
         name={name}
         id={id}
         autoComplete="new-password"
         className={className}
      />
   );
}
