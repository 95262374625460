import { useEffect, useRef, useState } from 'react';
import { MfaValidateMethodProps } from '../MfaValidate';
import { userLogin } from '../../../lib/passkey';
import BlueButton from '../../BlueButton';

export default function MfaValidatePasskey({
   onSuccess,
}: MfaValidateMethodProps) {
   const [cancelled, setCancelled] = useState(false);

   const authenticate = (signal?: AbortSignal) => {
      setCancelled(false);
      userLogin(signal).then(onSuccess, () => setCancelled(true));
   };

   useEffect(() => {
      const abortController = new AbortController();
      authenticate(abortController.signal);
      return () => abortController.abort();
   }, []);

   return (
      <>
         <h2 className="mb-6 text-left text-2xl font-extrabold text-gray-900">
            Passkey authentication
         </h2>
         <p>Follow the prompts from your browser.</p>
         {cancelled && (
            <p>
               <BlueButton className="py-2 px-4" onClick={() => authenticate()}>
                  Try again
               </BlueButton>
            </p>
         )}
      </>
   );
}
