import { useHistory, useLocation } from 'react-router-dom';
import { SubDocRequestGet, SubDocRequestPatch } from 'types';
import useGetNonTypeSafe from '../hooks/useGetNonTypeSafe';
import { api } from '../lib/api';
import { formatDateString } from '../lib/date';
import RequestDownloads from './RequestDownloads';
import { lazy, Suspense, useState } from 'react';
import SendRequestModal from '../pages/Request/SendRequestModal';
import FormModal from './FormModal';
import Banner from './Banner';
const Survey = lazy(() => import('./SurveyForm/Survey'));

interface Props {
   requestId: number;
}

export default function Request({ requestId }: Props) {
   const [data, error, loading, refresh] = useGetNonTypeSafe<SubDocRequestGet>(
      `request/${requestId}/?includeParamsSurveyCode=true`,
   );

   const history = useHistory();

   const [requestToken, setRequestToken] = useState<string>();
   const [paramsModal, setParamsModal] = useState(false);

   const markIncompleteRequest = () => {
      api.patch<SubDocRequestPatch>(`request/${requestId}`, {
         completed: false,
      })
         .then(res => refresh())
         .catch(err => alert(err.message));
   };

   const deleteRequest = () => {
      const confirm = window.confirm(
         'Are you sure you want to delete this request?\n\nThis action cannot be undone!',
      );

      if (!confirm) {
         return;
      }
      api.delete(`request/${requestId}`)
         .then(res => history.goBack())
         .catch(err => alert(err.message));
   };

   const archiveRequest = () => {
      const confirm = window.confirm(
         'Are you sure you want to archive this request?',
      );

      if (!confirm) {
         return;
      }
      api.patch<SubDocRequestPatch>(`request/${requestId}`, { archive: true })
         .then(res => history.goBack())
         .catch(err => alert(err.message));
   };

   const unarchiveRequest = () => {
      api.patch<SubDocRequestPatch>(`request/${requestId}`, { archive: false })
         .then(res => refresh())
         .catch(err => alert(err.message));
   };

   const getRequestToken = () =>
      api
         .get<string>(`request/${requestId}/generate-link`)
         .then(setRequestToken);

   if (['Not Found', 'Forbidden'].includes(error || '')) {
      //can't use <NotFound /> because it will render insinde of requests page and not look good
      history.push('/404');
   }

   return (
      <>
         {requestToken && (
            <SendRequestModal
               requestToken={requestToken}
               onClose={() => setRequestToken(undefined)}
            />
         )}
         {paramsModal && (
            <FormModal
               title="Configure Form"
               display={paramsModal}
               onClose={() => setParamsModal(false)}
            >
               <EditParamsSurveyCode
                  requestId={requestId}
                  paramsSurveyCode={data?.paramsSurveyCode!}
                  params={data?.params}
                  onSubmit={() => {
                     refresh();
                     setParamsModal(false);
                  }}
                  onCancel={() => setParamsModal(false)}
               />
            </FormModal>
         )}
         <div className="max-w-3xl mx-auto">
            <div className="pb-1 md:pb-5 flex flex-wrap gap-3 justify-between">
               <h1 className="text-3xl font-bold leading-tight text-gray-900 text-left">
                  Request
               </h1>
               {data && (
                  <div className="flex gap-2">
                     <button className="basic-btn" onClick={getRequestToken}>
                        Regenerate Link
                     </button>

                     {data.completedAt ? (
                        <>
                           <button
                              className="basic-btn"
                              onClick={markIncompleteRequest}
                           >
                              Mark Incomplete
                           </button>

                           {data.paramsSurveyCode && (
                              <button
                                 className="basic-btn"
                                 onClick={() => setParamsModal(true)}
                              >
                                 Update Params and Reopen
                              </button>
                           )}

                           {data.archived ? (
                              <button
                                 className="basic-btn"
                                 onClick={unarchiveRequest}
                              >
                                 Un-Archive
                              </button>
                           ) : (
                              <button
                                 className="delete-btn"
                                 onClick={archiveRequest}
                              >
                                 Archive
                              </button>
                           )}
                        </>
                     ) : (
                        <button className="delete-btn" onClick={deleteRequest}>
                           Delete
                        </button>
                     )}
                  </div>
               )}
            </div>
            <div className="mt-3 flex sm:mt-0 sm:ml-4" />
            {data && (
               <div className="px-4 py-5 sm:p-6">
                  <div className="mt-0">
                     <div className="mb-4">
                        <h3 className="text-xl">{data.fundName}</h3>
                        <h4 className="text-lg">
                           {data.userEmail ?? data.accountName}
                        </h4>
                        {data.secondUserEmail && (
                           <h4 className="text-lg">{`${data.secondUserEmail}`}</h4>
                        )}
                        {data.investorName && (
                           <h4 className="text-lg">{`${data.investorName}`}</h4>
                        )}
                        <h5 className="text-lg text-gray-600">
                           Form: {data.formName}
                        </h5>

                        <p>Sent: {formatDateString(data.createdAt)}</p>
                        {data.completedAt && (
                           <p>
                              Completed: {formatDateString(data.completedAt)}
                           </p>
                        )}
                        <RequestStatusBadge
                           startedAt={data.startedAt}
                           completedAt={data.completedAt}
                        />
                     </div>
                     {/* content */}
                     <RequestDownloads requestId={requestId} rawData={true} />
                  </div>
               </div>
            )}
         </div>
      </>
   );
}

function RequestStatusBadge({
   startedAt,
   completedAt,
}: {
   startedAt: Date | null;
   completedAt: Date | null;
}) {
   let status;
   let badgeClassName;

   if (completedAt) {
      status = 'Complete';
      badgeClassName = 'bg-emerald-500';
   } else if (startedAt) {
      status = 'In Progress';
      badgeClassName = 'bg-amber-500';
   } else {
      status = 'New';
      badgeClassName = 'bg-blue-500';
   }

   return (
      <span className={`${badgeClassName} p-1 rounded-md inline-block`}>
         {status}
      </span>
   );
}

interface EditParamsSurveyCodeProps {
   requestId: number;
   paramsSurveyCode: {};
   params?: {} | null;
   onSubmit: () => void;
   onCancel: () => void;
}

function EditParamsSurveyCode({
   requestId,
   paramsSurveyCode,
   params,
   onSubmit,
   onCancel,
}: EditParamsSurveyCodeProps) {
   const [formValues, setFormValues] = useState(params);
   const [error, setError] = useState('');

   const handleParamsSurveyUpdate = (paramsSurveyCode: any) => {
      setError('');

      const confirm = window.confirm(
         `Are you sure you want to update the params and reopen the request?`,
      );
      if (!confirm) {
         onCancel();
         return;
      }

      api.patch<SubDocRequestPatch>(`request/${requestId}`, {
         completed: false,
         params: paramsSurveyCode.data,
      })
         .then(onSubmit)
         .catch(err => setError(err.message));
   };

   return (
      <Suspense
         fallback={<div className="justify-center text-center">Loading...</div>}
      >
         {error && <Banner type="error">{error}</Banner>}
         <Survey
            showCompletedPage={false}
            theme="modern"
            showTitle={false}
            showProgressBar="off"
            json={paramsSurveyCode}
            responseData={formValues}
            onValueChanged={(model: any) => {
               setFormValues(model.data);
            }}
            onComplete={handleParamsSurveyUpdate}
         />
      </Suspense>
   );
}
