import { useEffect, useRef, useState } from 'react';
import { mfaApiCall, mfaApiLogin } from '../../../lib/api';
import { MfaEmailResponse } from 'types/interface';
import BlueButton from '../../BlueButton';

interface Props {
   isSetup?: boolean;
   onSuccess: () => void;
}

export default function MfaEmailForm({ isSetup = false, onSuccess }: Props) {
   const [codeToVerify, setCodeToVerify] = useState('');
   const [email, setEmail] = useState('');
   const [error, setError] = useState('');
   const [loading, setLoading] = useState(false);

   const url = isSetup ? 'mfa/setup/email' : 'mfa/email/send';

   const sendOtcEmail = () => {
      mfaApiCall<MfaEmailResponse>('post', url)
         .then(res => setEmail(res.email))
         .catch(err => setError(err.message));
   };

   useEffect(sendOtcEmail, []);

   const inputRef = useRef<HTMLInputElement>(null);
   useEffect(() => inputRef?.current?.focus(), []);

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCodeToVerify(e.target.value);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!codeToVerify) {
         return setError('Please enter code');
      }

      setLoading(true);
      mfaApiLogin('post', 'mfa/email/verify', {
         code: codeToVerify,
      })
         .then(() => onSuccess())
         .catch(err => setError(err.message ?? `The code is incorrect`))
         .finally(() => setLoading(false));
   };

   return (
      <>
         <h2 className="mb-1 text-left text-3xl font-extrabold text-gray-900">
            Enter verification code
         </h2>
         {error ? (
            <div className="bg-red-200 p-4 rounded-md text-left my-2">
               {error}
            </div>
         ) : null}
         <h3 className="mb-4 font-regular text-gray-600">
            <p>We sent a code to your email address {email}</p>
         </h3>

         <form className="space-y-6 group" onSubmit={handleSubmit}>
            <div>
               <label
                  htmlFor="verification-code"
                  className="block text-sm font-medium text-gray-700"
               >
                  Verification Code
               </label>
               <div className="mt-1">
                  <input
                     ref={inputRef}
                     value={codeToVerify}
                     onChange={handleChange}
                     id="verification-code"
                     name="verification-code"
                     type="text"
                     autoComplete="one-time-code"
                     inputMode="numeric"
                     minLength={6}
                     maxLength={6}
                     required
                     pattern="\d{6,6}"
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </div>
            </div>
            <div>
               <BlueButton
                  type="submit"
                  loading={loading}
                  className="w-full block group-invalid:pointer-events-none group-invalid:opacity-30"
               >
                  Verify
               </BlueButton>
               <ResendLink onClick={sendOtcEmail} />
            </div>
         </form>
      </>
   );
}

function ResendLink({ onClick }: { onClick: () => void }) {
   const TIME_LIMIT = 180;
   const [timeRemaining, setTimeRemaining] = useState(TIME_LIMIT);
   useEffect(() => {
      const interval = setInterval(
         () => setTimeRemaining(time => time - 1),
         1000,
      );
      return () => clearInterval(interval);
   }, []);

   const canResend = timeRemaining <= 0;

   const handleClick = () => {
      onClick();
      setTimeRemaining(TIME_LIMIT);
   };

   const formattedTime =
      timeRemaining > 0
         ? `${Math.floor(timeRemaining / 60)}:${String(timeRemaining % 60).padStart(2, '0')} minutes`
         : '';

   return (
      <div className="my-4">
         {canResend ? (
            <button
               type="button"
               className="link my-4 block text-sm text-blue-400"
               onClick={handleClick}
            >
               Resend email
            </button>
         ) : (
            <p className="text-gray-400">
               You can resend the email in {formattedTime}
            </p>
         )}
      </div>
   );
}
