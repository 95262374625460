import Button, { ButtonProps } from './Button';

export default function GreyButton({
   onClick,
   type = 'button',
   children,
   className,
   textColor,
   disabled = false,
   loading = false,
}: ButtonProps) {
   textColor = textColor ?? 'text-gray-700';

   return (
      <Button
         type={type}
         className={`leading-4 border-gray-300 bg-white hover:bg-gray-50 ${textColor} ${className || ''}`}
         onClick={onClick}
         disabled={disabled}
         loading={loading}
      >
         {children}
      </Button>
   );
}
